.game {
  width: 100%;
  height: 100%;
  touch-action: none;
}

.title {
  color: #fff;
  text-align: center;
  font-family: IQOS Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 124.7%; /* 34.916px */
  text-transform: uppercase;
}

.gameContainer {
  overflow: hidden;
}

.game-slot {
  background: #e9fbff;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 9vh;
  height: 9vh;
}

.game-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  touch-action: none;
}

.border {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.game-phone {
  padding: 10px;
  border-radius: 36px;
  background: #e9fbff;
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  overflow: hidden;
  /* animation: floatingAnimation 5s ease-in-out infinite; */
  justify-content: center;
  touch-action: none;
  width: 40vh;
  height: 65vh;
}

.text1 {
  color: rgba(255, 255, 255, 0.658);
  text-align: center;
  font-size: 30px;
  font-weight: 300;
  margin-top: 50%;
}

.text2 {
  color: orange;
  font-size: 40px;
  text-align: center;
}

.restart {
  font-size: 100px;
  cursor: pointer;
  font-weight: 700;
  text-align: center;
  color: rgba(255, 255, 255, 0.658);
}

.restart:hover {
  color: orangered;
  transform: scale(0.9);
}

@keyframes floatingAnimation {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes floatingAnimation2 {
  0% {
    transform: translateY(0);
    opacity: 0; /* Полностью прозрачный */
  }
  50% {
    transform: translateY(-5px);
    opacity: 0; /* Полностью прозрачный */
  }
  100% {
    transform: translateY(0);
    opacity: 1; /* Полностью прозрачный */
  }
}

@-webkit-keyframes AnimateBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes AnimateBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media screen and (max-height: 700px) {
  .title {
    font-size: 22px;
  }
}
@media screen and (max-width: 380px) {
  .title {
    font-size: 22px;
  }
}
@media screen and (max-width: 260xpx) {
  .game-phone {
    width: 40vh;
    height: 50vh;
  }
}

@media screen and (max-width: 275px) {
  .game-phone {
    width: 40vh;
    height: 50vh;
  }
  .game-slot {
    width: 7vh;
    height: 7vh;
  }
}
