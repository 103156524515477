.guide {
  padding: 20px 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  max-width: 450px;
}

.title {
  color: #fff;
  font-family: IQOS Sans;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 107.2%; /* 42.88px */
  text-transform: uppercase;
  text-align: center;
}

.text {
  padding: 16px 30px;
  border-radius: 36px;
  background: #e9fbff;
  color: #002460;
  font-family: IQOS Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 33.8px */
  text-align: center;
}

.button {
  width: 225px;
  padding: 15.233px 47.148px 21.761px 47.148px;
  border-radius: 64.278px;
  border: 5.596px solid #fff;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(2.1392529010772705px);
  color: #fff;
  text-align: center;
  font-family: IQOS Sans;
  font-size: 34.473px;
  font-style: normal;
  font-weight: 700;
  line-height: 107.2%; /* 36.955px */
}

.gif {
  width: 100%;
}

.gif1 {
  max-width: 100%;
}
.gif2 {
  min-width: 70%;
}

.gif3 {
  max-width: 100%;
}
.gifContainer {
  margin: 40px 0;

  width: 100%;
  gap: 20px;
  display: flex;
  height: 20vh;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-height: 900px) {
  .text {
    font-size: 22px;
  }
}

@media screen and (max-height: 800px) {
  .guide {
    gap: 20px;
  }
  .text {
    font-size: 20px;
  }
}

@media screen and (max-height: 700px) {
  .text {
    font-size: 16px;
  }
}
