.choice {
  position: relative;
  /* height: 100%; */
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  scrollbar-width: thin;
}

.title {
  color: #fff;
  text-align: center;
  font-family: IQOS Sans;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%; /* 35px */
  text-transform: uppercase;
}

.container {
  border-radius: 36px;
  background: #e9fbff;
  min-width: 40px;
  min-height: 50px;
  max-height: 50vh;
  overflow: hidden;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  -webkit-box-shadow: 0px -40px 17px 0px rgba(150, 198, 234, 0.55) inset;
  -moz-box-shadow: 0px -40px 17px 0px rgba(150, 198, 234, 0.55) inset;
  box-shadow: 0px -40px 17px 0px rgba(150, 198, 234, 0.55) inset;
}

.formContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  overflow-y: scroll;
}

.formButton {
  background: transparent;
  border: none;
}

.form {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 32px;
  max-height: 500px;
  overflow: scroll;
  padding-bottom: 20px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.button {
  width: 225px;
  padding: 15.233px 47.148px 21.761px 47.148px;
  border-radius: 64.278px;
  border: 5.596px solid #fff;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(2.1392529010772705px);
  color: #fff;
  text-align: center;
  font-family: IQOS Sans;
  font-size: 34.473px;
  font-style: normal;
  font-weight: 700;
  line-height: 107.2%; /* 36.955px */
}

.formTitle {
  text-align: center;
  opacity: 0.8;
  background: #083d8b;
  width: 100%;
  padding: 15px 0px;
  color: #fff;
  font-family: IQOS Sans;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 26.403px; /* 114.794% */
  letter-spacing: 0.813px;
  margin-right: 15px;
}

.scroll {
  position: absolute;
  right: 0px;
  height: 100px;
  background: #083d8b;
  width: 3px;
  transition: all 0.1s ease-out;
}

::-webkit-scrollbar {
  width: 0;
  opacity: 0;
}

@media screen and (max-height: 700px) {
  .title {
    font-size: 22px;
  }
  .button {
    width: 175px;
    font-size: 24.473px;
    padding: 10px 0;
  }
}
@media screen and (max-width: 380px) {
  .title {
    font-size: 22px;
  }
  .button {
    width: 175px;
    font-size: 24.473px;
    padding: 10px 0;
  }
}
