.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
}

.itemName {
  text-align: center;
  font-family: IQOS Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 20px */
  text-transform: uppercase;
}

.itemBoldText {
  color: #34303d;
  text-align: center;
  font-family: IQOS Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 16.5px */
}

.itemText {
  color: #34303d;
  font-family: IQOS Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.itemLightText {
  color: #34303d;
  font-family: IQOS Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 300;
  line-height: 110%;
}

@media screen and (max-height: 800px) {
  .item {
    gap: 7px;
  }
  .itemName {
    font-size: 15px;
  }
  .itemBoldText {
    font-size: 11px;
  }

  .itemText {
    font-size: 11px;
  }

  .itemLightText {
    font-size: 11px;
  }
}
