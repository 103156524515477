.finalScreen {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 55% 5% 10% 5%;
  align-items: center;
  background-image: url('../../images/backgrond.png');
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  overflow: hidden;
}

.title {
  color: #fff;
  text-align: center;
  font-family: IQOS Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 149.7%; /* 47.904px */
  text-transform: uppercase;
  z-index: 1;
}

.button {
  padding: 17px 10px;
  width: fit-content;
  border-radius: 64.278px;
  border: 5.596px solid #fff;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(5px);
  color: #fff;
  text-align: center;
  font-family: IQOS Sans;
  font-size: 34.473px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  z-index: 1;
}

.img {
  position: absolute;
  top: 10%;
  width: 90%;
}

.link {
  color: white;
  z-index: 5;
  max-width: 90%;
}
