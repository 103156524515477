.label {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5%;
  position: relative;
  height: 53px;
}
.point {
  padding: 20px;
  margin: -20px;
}

.text {
  font-family: IQOS Sans;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 26.403px; /* 114.794% */
  letter-spacing: 0.813px;
  text-transform: uppercase;
  max-width: 60%;
}

.img {
  width: 40px;
  height: 40px;
}

.input {
  position: absolute;
  opacity: 0;
  width: 20px;
  height: 20px;
}
