.yourchoice {
  padding: 5% 5%;
  width: 100%;
  /* min-height: 100vh; */
  box-sizing: border-box;
}

.container {
  width: 100%;
  height: 100%;
  min-height: 80vh;
  border-radius: 50px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 24px 20px;
  box-sizing: border-box;
}

.header {
  display: flex;
  justify-content: center;
  gap: 15px;
  position: relative;
  width: 100%;
}

.headerButton {
  position: absolute;
  left: 2px;
  top: 3px;
  background: transparent;
  border: none;
}

.headerText {
  color: #000;
  text-align: center;
  font-family: IQOS Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%; /* 30.8px */
  letter-spacing: 2px;
}

.button {
  width: 225px;
  padding: 15.233px 47.148px 21.761px 47.148px;
  border-radius: 64.278px;
  border: 5.596px solid #124c7c;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(2.1392529010772705px);
  color: #124c7c;
  text-align: center;
  font-family: IQOS Sans;
  font-size: 34.473px;
  font-style: normal;
  font-weight: 700;
  line-height: 107.2%; /* 36.955px */
}

@media screen and (max-height: 700px) {
  .headerText {
    font-size: 22px;
  }
  .button {
    width: 175px;
    font-size: 24.473px;
    padding: 10px 0;
  }
  .headerButton {
    top: 0;
  }
}

@media screen and (max-width: 380px) {
  .headerText {
    font-size: 22px;
  }
  .button {
    width: 175px;
    font-size: 24.473px;
    padding: 10px 0;
  }
  .headerButton {
    top: 0;
  }
}
