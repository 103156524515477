.main {
  background-image: url(../../images/backgrond.png);
  background-size: cover;
  background-repeat: no-repeat;
  /* height: 100%; */
  box-sizing: border-box;

  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
